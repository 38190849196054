.apply-page-wrapper {
  margin-top: 60px;
  margin-bottom: 60px;
}

.apply-picture-container {
  height: 275px;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('https://hackbrightacademy.com/content/uploads/2017/04/IMG_0752.jpg');
  position: relative;
}

.apply-picture-container::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.picture-div {
  color: white;
  font-weight: bold;
  font-size: 42px;
  padding: 30px 15px;
  z-index: 2;
  text-align: center;
}

.apply-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-info-container {
  padding: 55px 35px;
  display: flex;
  flex-direction: column;
  max-width: 900px;
}

.content-header {
  color: #fb3530;
  font-size: 27px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2.7px;
  line-height: 1.56;
  font-family: 'Montserrat', Arial, Tahoma, sans-serif;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.content-text {
  color: #666;
  font-size: 21px;
  font-weight: 300;
  text-align: center;
  font-family: Lato;
  line-height: 1.57;
  letter-spacing: 0.5px;
}

.cohorts-main-container {
  max-width: 900px;
}

.cohort-container {
  width: 350px;
  position: relative;
  margin: 10px;
  margin-bottom: 48px;
}

.cohort-container h5 {
  margin: 0;
}

.apply-button {
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  border-color: #fb3530;
  border: 1px solid;
  background: transparent;
  color: #fb3530;
  width: 100%;
  /* position: absolute; */
  bottom: 0;
}

.apply-button:hover {
  color: white;
  background: #fb3530;
  cursor: pointer;
}

.program-section {
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  padding: 8px 16px;
  margin-bottom: 32px;
}

.program-section h2 {
  color: #fb3530;
  font-size: 20px;
  margin-bottom: 40px;
}

.program-section section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.page-wrapper {
  padding: 0;
}

@media (max-width: 880px) {
  .program-section section {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 650px) {
  .picture-div {
    line-height: 40px;
  }
}

@media (min-width: 1800px) {
  .apply-picture-container {
    height: 350px;
  }
}
