* {
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow: auto;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: auto;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, p {
  font-family: "Montserrat", Arial, Tahoma, sans-serif;
} */

/* Master Classes */
* {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 24px;
  color: #706e6b;
  -webkit-overflow-scrolling: touch;
}

.height-wrapper {
  height: 100%;
  position: relative;
}

.pointer:hover {
  cursor: pointer;
}

input {
  padding: 6px 15px;
}

input:focus {
  outline: 0;
}

button:focus {
  outline: 0;
}

button:hover {
  cursor: pointer;
}

select:focus {
  outline: 0;
}

textarea:focus {
  outline: 0;
}

.page-wrapper {
  min-height: calc(100vh - 100px);
}

.view-page-container {
  padding: 60px 50px 0px 50px;
}

.view-page-wrapper {
  max-width: 1000px;
  padding: 30px 0px 30px 0px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.view-page-wrapper > div {
  width: 100%;
}

.hidden {
  display: none !important;
}

.error-message {
  font-size: 12px;
  color: red;
  margin-top: 4px;
  margin-bottom: 0px;
}

.red-strike-through {
  color: red;
  text-decoration: line-through;
}

.red-strike-through > span {
  margin-right: 10px;
}

h5 {
  color: #706e6b;
  font-family: Lato;
  font-size: 17px;
  font-weight: bold;
  line-height: 22px;
}

.form-section {
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  padding: 8px 16px 0px 16px;
  margin-bottom: 31px;
  width: 100%;
}

.btn-red {
  border-radius: 5px;
  background-color: #ff3235;
  color: white;
  border: none;
  height: 38px;
  width: 192px;
}

.btn-red:hover {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.35);
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.9)
    rgba(0, 0, 0, 0.7);
}

.btn-red span {
  color: white;
}

.btn-white-red {
  border: 1px solid #ff3235;
  border-radius: 5px;
  color: #ff3235;
  height: 38px;
  width: 192px;
  background: transparent;
}

.btn-white-red:hover {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.35);
  border: none;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.9)
    rgba(0, 0, 0, 0.7);
  background-color: #ff3235;
}
.btn-white-red:hover span {
  color: white;
}

.btn-white-red span {
  color: #ff3235;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
}

.centered {
  margin: auto;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  justify-content: center;
}

.form-spacing {
  margin-right: 16px;
  margin-bottom: 32px;
}

.contact-form-spacing {
  margin-bottom: 16px;
}

.form-button-row {
  margin-bottom: 32px;
}

.input-text-small {
  height: 34px;
  width: 105px;
  border: 1px solid #cdcdcd;
  border-radius: 2px;
}

.input-text-normal {
  height: 32px;
  width: 238px;
  border: 1px solid #afafaf;
  border-radius: 2px;
}

.input-text-large {
  height: 52px;
  width: 566px;
  border: 1px solid #afafaf;
  border-radius: 2px;
}

.input-text-contact-form {
  height: 32px;
  width: 500px;
  border: 1px solid #afafaf;
  border-radius: 2px;
}

.input-text-contact-form:focus {
  outline: none;
  box-shadow: 0 0 0 5px rgba(73, 73, 73, 0.1);
}

.input-dropdown {
  height: 40px;
  width: 209px;
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  font-size: 16px;
}

.contact-input-dropdown {
  height: 40px;
  width: 500px;
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  font-size: 16px;
}

.contact-input-dropdown:focus {
  outline: none;
  box-shadow: 0 0 0 5px rgba(73, 73, 73, 0.1);
}

.red-star {
  color: #ff3235;
}

.internal-link {
  color: #5876a3;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
  line-height: 25px;
  display: inline-block;
  margin-bottom: 30px;
}

.circle-container {
  display: flex;
  justify-content: center;
}

.circle {
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px;
}

.circle:hover {
  cursor: pointer;
}

.red {
  background-color: #ff3235;
}

.disabled-button {
  background-color: #a4a4a4;
}

.disabled-button:hover {
  cursor: auto;
}

.page-heading {
  color: #58595b;
  font-size: 30px;
  line-height: 37px;
  font-family: Montserrat;
  text-align: center;
  margin-bottom: 24px;
  font-weight: 400;
}

.page-sub-heading {
  color: #58595b;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 24px;
  font-weight: 400;
}

.info-text {
  color: #58595b;
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
  margin-bottom: 44px;
}

.italic-mont {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
  margin-bottom: 24px;
}

.red-underline {
  color: #ff3235;
  text-decoration: underline;
}

.bolded-text {
  color: #58595b;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 24px;
}

.international-warning {
  color: #666;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  font-family: Lato;
  line-height: 1.57;
  letter-spacing: 0.5px;
}

.international-warning b {
  font-weight: 500;
  font-size: 18px;
}

.international-warning a {
  font-weight: 400;
  font-size: 16px;
}

@media (max-width: 700px) {
  .input-text-large {
    width: 400px;
  }
  .circle {
    height: 16px;
    width: 16px;
  }
}

@media (max-width: 600px) {
  .input-text-large {
    width: 100%;
  }

  .navigation-buttons {
    flex-direction: column-reverse;
    align-items: center;
    height: 90px;
  }
}

@media (max-width: 500px) {
  .form-spacing {
    width: 100%;
  }

  .heading-section {
    padding: 0px 30px;
  }

  .input-text-normal {
    width: 100%;
  }

  .page-wrapper {
    padding: 60px 0px 0px 0px;
  }

  .form-section {
    border-left: none;
    border-right: none;
  }
}

@media (max-width: 400px) {
  .input-dropdown {
    width: 100%;
  }
}
/* Components Styles */

/* -- Header -- */
.header-main-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  z-index: 3;
  align-items: center;
  padding: 35px 20px;
}

.hamburger-container {
  display: none;
}

.logo-container {
  width: 15%;
}

.logo-container img {
  width: 195px;
  height: 48px;
}

.hacklink-button {
  height: 35px;
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  background-color: #ffffff;
  padding: 0px 41px;
  color: #ee1431;
  text-decoration: none;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.47px;
  line-height: 35px;
}

@media (max-width: 550px) {
  .hacklink-button {
    padding: 0px 24px;
  }
}

@media (max-width: 500px) {
  .hacklink-button {
    display: none;
  }
  .header-main-container {
    justify-content: center;
  }
  .logo-container {
    width: auto;
  }
}
/* -- Footer -- */
.footer-main-container {
  border-top: 2px solid #eeeeee;
  height: 100px;
}

.footer-width-container {
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.links-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 920px;
  margin-bottom: 40px;
}

.link-section {
  display: flex;
  flex-direction: column;
}

.link-section span {
  color: #b2b2b2;
}

.link-section a {
  text-decoration: none;
  color: #666666;
}

.legal-social-container {
  display: flex;
  width: 100%;
}

.legal-section {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.legal-section a {
  color: #808080;
  text-decoration: none;
  margin: 0 10px;
}
.social-section {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 22px;
}

.social-bubble {
  color: #666;
  border: 1px solid #666;
  border-radius: 50%;
  height: 41px;
  width: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
}

.social-bubble:hover {
  cursor: pointer;
}

@media (max-width: 767px) {
  .footer-main-container {
    height: 135px;
  }
  .footer-width-container {
    padding: 15px 20px;
    flex-direction: column-reverse;
  }
  .legal-section {
    width: 100%;
    justify-content: center;
  }
  .social-section {
    width: 100%;
    justify-content: center;
  }
  .page-wrapper {
    min-height: calc(100vh - 135px);
  }
}

/* Content Modal */
.modal-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-window-close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal-window-close:hover {
  cursor: pointer;
}

.modal-container-main {
  position: absolute;
  width: 100%;
  min-height: 75vh;
  max-width: 850px;
  background-color: white;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.4);
  padding-top: 27px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 41px;
}

.modal-header-container {
  text-align: center;
  margin-bottom: 30px;
}

.modal-header-container p {
  font-size: 17px;
  letter-spacing: 1.1px;
  color: #666666;
  line-height: 19px;
}

.modal-form {
  font-family: 'Lato', Arial, Tahoma, sans-serif;
  font-weight: 700;
  letter-spacing: 1.1px;
  color: #666;
}
.modal-form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  line-height: 1.4em;
}

.modal-form-row div {
  display: flex;
  justify-content: space-between;
}

.modal-form-row input {
  padding: 7px 0;
  border-radius: 4px;
  font-size: 14px;
  border: 2px solid #cdcdcd;
  width: 100%;
}

.modal-form-row input:hover {
  outline: none;
  box-shadow: 0 0 0 5px rgba(73, 73, 73, 0.1);
}

.modal-form-row select {
  padding: 7px 0;
  border-radius: 4px;
  font-size: 14px;
  border: 2px solid #cdcdcd;
  width: 100%;
  -webkit-appearance: menulist;
  -webkit-rtl-ordering: logical;
}

.modal-form-row select::-ms-expand {
  display: block;
}

.modal-form-row select:hover {
  outline: none;
  box-shadow: 0 0 0 5px rgba(73, 73, 73, 0.1);
}

.contact-form-button {
  width: 500px;
  height: 40px;
  background-color: #fb3530;
  font-size: 17px;
  letter-spacing: 1.8px;
  text-align: center;
  color: white;
  font-family: 'Lato', Arial, Tahoma, sans-serif;
  border: none;
  margin-top: 8px;
}

.modal-form-row button:hover {
  cursor: pointer;
}

.form-star {
  color: #ffc500;
}
@media (max-width: 767px) {
  .modal-container-main {
    max-width: 100%;
  }
}

@media (max-width: 700px) {
  .modal-container-main {
    height: 100%;
  }
}

/* Side bar */

.side-bar-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1001;
  transition: background-color 2s;
}

.side-bar-main-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 250px;
  background-color: white;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.side-bar-header {
  display: flex;
  justify-content: flex-start;
}

.side-bar-header svg {
  margin-top: 13px;
  margin-left: 13px;
}

.side-bar-header img {
  height: 46px;
  margin-top: 11px;
  margin-left: 57px;
}

.application-heading {
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url('https://hackbrightacademy.com/content/uploads/2019/12/Hackbright-Academy_Application.jpg');
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  margin: 60px;
}

.application-heading h1 {
  text-align: center;
  line-height: 2.5rem;
  color: white;
  font-size: 2.75rem;
  padding: 8rem 2rem;
}

.application-sub-heading p {
  font-weight: 400;
  text-align: center;
  margin: 0;
}

.application-sub-heading {
  margin: 1rem 0;
}

.link-with-action {
  text-decoration: underline;
}

.link-with-action:hover {
  cursor: pointer;
}

.apply-page-wrapper {
  margin-top: 60px;
  margin-bottom: 60px;
}

.apply-picture-container {
  height: 275px;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('https://hackbrightacademy.com/content/uploads/2017/04/IMG_0752.jpg');
  position: relative;
}

.apply-picture-container::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.picture-div {
  color: white;
  font-weight: bold;
  font-size: 42px;
  padding: 30px 15px;
  z-index: 2;
  text-align: center;
}

.apply-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-info-container {
  padding: 55px 35px;
  display: flex;
  flex-direction: column;
  max-width: 900px;
}

.content-header {
  color: #fb3530;
  font-size: 27px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2.7px;
  line-height: 1.56;
  font-family: 'Montserrat', Arial, Tahoma, sans-serif;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.content-text {
  color: #666;
  font-size: 21px;
  font-weight: 300;
  text-align: center;
  font-family: Lato;
  line-height: 1.57;
  letter-spacing: 0.5px;
}

.cohorts-main-container {
  max-width: 900px;
}

.cohort-container {
  width: 350px;
  position: relative;
  margin: 10px;
  margin-bottom: 48px;
}

.cohort-container h5 {
  margin: 0;
}

.apply-button {
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  border-color: #fb3530;
  border: 1px solid;
  background: transparent;
  color: #fb3530;
  width: 100%;
  /* position: absolute; */
  bottom: 0;
}

.apply-button:hover {
  color: white;
  background: #fb3530;
  cursor: pointer;
}

.program-section {
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  padding: 8px 16px;
  margin-bottom: 32px;
}

.program-section h2 {
  color: #fb3530;
  font-size: 20px;
  margin-bottom: 40px;
}

.program-section section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.page-wrapper {
  padding: 0;
}

@media (max-width: 880px) {
  .program-section section {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 650px) {
  .picture-div {
    line-height: 40px;
  }
}

@media (min-width: 1800px) {
  .apply-picture-container {
    height: 350px;
  }
}

.state-restrictions {
    font-weight: 400;
    margin-bottom: 1rem;
}
.link {
    color: blue;
}

