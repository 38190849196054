* {
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow: auto;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: auto;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, p {
  font-family: "Montserrat", Arial, Tahoma, sans-serif;
} */
